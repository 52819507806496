<template>
    <v-container fluid fill-height app class="d-flex align-start">
        <v-sheet height="100%" width="100%" class="rounded-lg">
            <v-toolbar flat class="rounded-lg">
                <div class="d-flex align-start mr-4">
                    <v-icon x-large color="primary">
                        {{ tableIcon }}
                    </v-icon>
                </div>
                <div class="d-flex flex-column align-start">
                    <span class="text-h6 font-weight-regular"> {{ tableName }}</span>
                </div>

                <v-spacer></v-spacer>

                <v-tooltip top v-if="crudPermission">
                    <template v-slot:activator="{on,attrs}">
                        <v-btn
                            color="primary"
                            v-on="on"
                            v-bind="attrs"
                            class="mb-2 mr-4 pa-0"
                            @click="importSheet"
                            :loading="importing"
                            min-width="48px"
                        >
                            <v-icon>mdi-file-excel</v-icon>
                        </v-btn>
                        <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                            @change="onFileChanged"
                        >
                    </template>
                    <span>Importar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{on,attrs}">
                        <v-btn
                            color="primary"
                            v-on="on"
                            v-bind="attrs"
                            class="mb-2 mr-4 pa-0"
                            @click="exportSheet"
                            :loading="exporting"
                            min-width="48px"
                            :disabled="noEventSelected"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Exportar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="primary" dark
                            v-on="on"
                            class="mb-2 pa-0"
                            min-width="48px"
                            @click="$router.push('/')"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Fechar</span>
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mx-4 mb-2"></v-divider>

            <v-container fluid class="py-0 px-4">
                <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 pa-0">
                        <v-autocomplete
                            v-model="selectedEvent"
                            label="Evento"
                            :items="events"
                            item-text="text"
                            :loading="fetchingEvents"
                            clearable
                            return-object
                            hide-no-data
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-container fluid class="d-flex flex-column align-center justify-center mt-12">
                    <p class="mb-6">{{ statusMessage }}</p>
                </v-container>
            </v-container>
        </v-sheet>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Consolidation',

    components: { },

    computed: {
        ...mapGetters('auth', ['getMenu']),

        tableName() {
            const tableName = this.getTableInfo().tableName

            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon

            return tableIcon ? tableIcon : 'mdi-view-list'
        },

        noEventSelected() {
            return !this.selectedEvent ? true : false
        },

        statusMessage() {
            let message = ''

            if (this.fetchingEvents) {
                message = 'Importando eventos...'
            } else if (!this.events.length) {
                message = 'Nenhum evento cadastrado'
            } else if (!this.selectedEvent) {
                message = 'Selecione um evento para iniciar a importação ou importe uma planilha base com a nova chave de consolidação'
            } else if (this.selectedEvent && !this.exported) {
                message = 'Exporte a planilha base para realizar a alteração'
            } else if (this.exporting && !this.exported) {
                message = 'Exportando planilha base...'
            } else if (this.exported) {
                message = 'Realize a alteração na planilha base e importe-a'
            }

            return message
        }
    },

    data() {
        return {
            events: [],
            selectedEvent: null,
            fetchingEvents: false,

            importing: false,
            exporting: false,
            exported: false,
            imported: false,

            crudPermission: true,
        }
    },

    created() {
        let routePath = this.$route.path
        const permissions = this.$getPermissions(routePath)
        for (let key in permissions) {
            this[key] = permissions[key]
        }

        this.getEvents()
    },

    methods: {
        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (let i = 0; i < this.getMenu.length; i++) {
                let submenu = this.getMenu[i].menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = this.getMenu[i].icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        async getEvents() {
            this.fetchingEvents = true

            const filter = {
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_module',
                        operator: '=',
                        value: 14
                    },
                    {
                        AndOr: 'AND',
                        column: 'id_status',
                        operator: '=',
                        value: 2
                    }
                ]
            }
            
            try {
                const res = await this.$http.post(this.$ipEvent + 'event/list-options', { filter })
                if (res) {
                    this.events = res.data.rows
                    this.fetchingEvents = false
                }
            } catch (err) {
                this.fetchingEvents = false
                this.$fnError(err)
            }
        },

        async exportSheet(){
            this.exporting = true

            let payload = {
                id_event: this.selectedEvent.id,
                id_account_group_version: this.selectedEvent.id_account_group_version,
            }

            try {
                const res = await this.$http.post(this.$ipPlPlanning + 'pl-planning/report', { ...payload })
                if (res) {
                    window.open(res.data.token)
                    this.$toast.success(res.data.msg)
                    this.exported = true
                    this.exporting = false
                }
            } catch (err) {
                this.exporting = false
                this.$fnError(err)
            }
        },

        importSheet(){
            this.$refs.uploader.click()
        },

        async onFileChanged(e) {
            if (e) {
                this.importing = true
                let sheet = e.target.files[0]
                let body = new FormData()
                body.append('spreadsheet', sheet)

                try {
                    const res = await this.$http.post(this.$ipPlPlanning + 'pl-planning/upload-spreadsheet',
                        body, { headers: { 'Content-Type': 'multipart/form-data' }}
                    )

                    if (res) {
                        let payload = {
                            ...res.data.id,
                            isSpreadsheetUpload: true
                        }

                        const massEffect = await this.$http.post(this.$ipPlPlanning + 'pl-planning/mass-effect', { ...payload })
                        if (massEffect) {
                            this.$toast.success(res.data.msg)
                            this.$refs.uploader.value = null
                            this.importing = false
                        }
                    }
                } catch (err) {
                    this.$refs.uploader.value = null
                    this.importing = false
                    this.$fnError(err)
                }
            }
        },
    },
}
</script>

<style>
</style>